/**--------------------------------------------------------------
 App - Main App Class
 By Stef Funaro
 www.stefetmarie.com
 --------------------------------------------------------------*/

import { Layout } from "./Layout";
import { Navigation } from "./Navigation";
import { Forms } from "./Forms";
import { ArticlesListing } from "./ArticlesListing";
import { SocialHelper } from "./../_helpers/SocialHelper";

var EventEmitter = require('events');

class App extends EventEmitter {

	/**--------------------------------------------------------------
     CONSTRUCTOR
     --------------------------------------------------------------*/

	constructor() {
		super();

		/**------------------------------------
         EVENTS
         -------------------------------------*/

		this.ON_APP_READY = "henri-on-app-ready-event";

	}

	init() {

		var instance = this;

		/**------------------------------------
         Properties - public
         -------------------------------------*/

		this.mainContainerId = "henri-project-body";
		this._el = document.getElementById(this.mainContainerId);
		this._$el = $(this._el);

		/**------------------------------------
         Properties - _private
         -------------------------------------*/

		//Protocol
		this._protocol = ('https:' == document.location.protocol ? 'https' : 'http');
		// console.log("_protocol obj=", _protocol);

		//isMobile
		this._isMobile = window.matchMedia("only screen and (max-width: 760px)");

		this.cssRootVariables = getComputedStyle(document.body);

		/**------------------------------------
         Init
         -------------------------------------*/

		//Layout
		this.layout = new Layout();
		this.layout.on(this.layout.ON_DOC_RESIZE, this._onDocResize.bind(this));

		//Navigation
		this.nav = new Navigation();
		this.nav.on(this.nav.ON_READY, this._onNavReady.bind(this));

		//Forms
		this.forms = new Forms();
		//Go!
		this.nav.init();
		this.forms.init();
		this.layout.start();

		//ProductsListing
		if (this._$el.hasClass("page-template-template-accueil")) {
			this.articlesListing = new ArticlesListing();
			this.articlesListing.init();
		} else if (this._$el.hasClass("search-results")) {
			this._$grid = $(".vignettes-container");
			this._$grid.masonry({
				// options
				itemSelector: '.grid-item',
				//columnWidth: 390,
				// gutter: 80
				transitionDuration: 0,
				gutter: '.gutter-sizer',
			});
			this._$grid.masonry('reloadItems');
			this._$grid.imagesLoaded().progress(function () {
				console.log("imageLoaded!");
				instance._$grid.masonry('layout');
			});
			this._setShare();
		} else {
			this._setShare();
		}

		$(".events-list-js").each(function () {
			var $eventsList = $(this);
			$eventsList.find(".event-select-js").change(function (pE) {
				console.log("on change=", $(this).val());
				$eventsList.find(".no-results").css("display", "none");
				var val = $(this).val();
				if (val == "null") {
					$eventsList.find(".contenu-event").fadeIn();
				} else {
					$eventsList.find(".contenu-event").hide();
					$eventsList.find(".contenu-event." + val).fadeIn();
					if ($eventsList.find(".contenu-event." + val).length < 1) {
						$eventsList.find(".no-results").css("display", "block");
					}
				}
			});
		});


		this._setAOS();
		//this._setMagnificPopup();
		// this._setSwiperCarousels();

		this._miscFixes();

		//Doc loaded
		if (window.henridocloaded) {
			instance._onDocLoaded();
		} else {
			$(window).on('load', this._onDocLoaded.bind(this));
		}


		console.log("Henri.App.init()", this.ON_APP_READY);

		this.emit(this.ON_APP_READY);

	}

	/**--------------------------------------------------------------
     PUBLIC
     --------------------------------------------------------------*/

	/**
	 * get a config
	 * @param pId {string}
	 */
	getConfig(pId) {
		return window.HenriConfigs[pId];
	}

	/**
	 * save a config
	 * @param pId {string}
	 * @param pData {object}
	 */
	saveConfig(pId, pData) {
		window.HenriConfigs[pId] = pData;
	}

	/**
	 * get a cookie
	 * @param pName {string}
	 */
	getCookie(pName) {
		var name = pName + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	/**
	 * set a cookie
	 * @param pName {string}
	 * @param pValue {string}
	 * @param pExdays {int}
	 */
	setCookie(pName, pValue, pExdays) {
		var d = new Date();
		d.setTime(d.getTime() + (pExdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = pName + "=" + pValue + ";" + expires + ";path=/";
	}

	/**
	 * get CSS Variables from root
	 */
	getCSSRootVariable(pName) {
		return this.cssRootVariables.getPropertyValue('--' + pName);
	}

	/**--------------------------------------------------------------
     GETTER
     --------------------------------------------------------------*/

	/**
	 * get main container
	 * @returns {Element|*}
	 */
	get container() {
		return this._el;
	}

	get $container() {
		return this._$el;
	}

	/**
	 * Is SLL ?
	 * @returns {boolean}
	 */
	get isHTTPS() {
		return ("https" === this._protocol);
	}

	/**
	 * Is mobile ?
	 * @returns {boolean}
	 */
	get isMobile() {
		return this._isMobile;
	}

	/**
	 * Get the social helper class (static class)
	 * @usage Henri.app.socialHelper.twitterShare("test");
	 * @returns {SocialHelper}
	 */
	get socialHelper() {
		return SocialHelper;
	}


	/**--------------------------------------------------------------
     PRIVATE
     --------------------------------------------------------------*/

	_setShare() {
		$('.js-sharethis').click(function (pE) {
			pE.preventDefault();
		});

		//Share popover
		var popoverTriggerList = [].slice.call(document.querySelectorAll('.js-sharethis'))
		var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
			var shareHtml = '<ul class="social-icons-popover">' +
				'<li class=""><a href="javascript:window.Henri.app.socialHelper.emailShare(\'{url}\',\'{subject}\');"><img src="/wp-content/uploads/2021/10/mail-ico.svg" alt="Courriel"></a></li>' +
				'<li class=""><a href="javascript:window.Henri.app.socialHelper.facebookShare(\'{url}\');"><img src="/wp-content/uploads/2021/10/facebook-ico.svg" alt="Facebook"></a></li>' +
				'<li class=""><a href="javascript:window.Henri.app.socialHelper.twitterShare(\'{url}\');"><img src="/wp-content/uploads/2021/10/Twiter-ico.svg" alt="Twitter"></a></li>' +
				'</ul>'

			return new bootstrap.Popover(popoverTriggerEl, {
				placement: "bottom",
				html: true,
				content: shareHtml.replaceAll("{url}", $(popoverTriggerEl).data("url")).replaceAll("{subject}", $(popoverTriggerEl).data("subject")),
				container: "#primary",
				trigger: 'click',
				sanitize: false,
				// sanitizeFn:function (content,el){
				// 	console.log("content!!=", content,el);
				// 	return content;
				// }
			});
		})
		$(document).mouseup(function () {
			$(".popover").popover('hide');
		})
	}

	/**
	 * Ajouter ici les divers petits fix et hacks
	 * @private
	 */
	_miscFixes() {

		//Pour régler les items qui ne sont pas cliquables sur touch
		$('.vc_tta-panel .vc_tta-panel-heading, .vc_tta-panel .vc_tta-panel-heading a, .dropdown-toggle, .vc_tta-tabs .vc_tta-tabs-container a').off('touchstart touchend');
		$('.vc_tta-panel .vc_tta-panel-heading a').on('click', function () {
			$('html, body').stop();
		});
		//if ($("#vc-primary").find(".wysiwyg-content").length > 0) {
			//if ($("#vc-primary .wysiwyg-content").find(".vc_row").length < 1) {
			if ($("#vc-primary").find(".vc_row").length < 1) {
				$("#vc-primary .title-box").addClass("no-vc");
				$("#vc-primary .wysiwyg-content").addClass("no-vc");
				$("#vc-primary .content-txt").addClass("no-vc");
			}
		//}

	}

	_onDocLoaded() {
		console.log("_onDocLoaded!");
	}

	_onDocResize(pW, pH) {
		// console.log("App>_onDocResize()",pW,pH);
		this.nav.onDocResize(pW, pH);
	}

	_onNavReady() {
		//console.log("Nav>_onNavReady()");
	}

	/**
	 * Set AOS (Animate on scroll)
	 * AOS:
	 * https://michalsnik.github.io/aos/
	 * Documentation:
	 * https://github.com/michalsnik/aos
	 *
	 * AOS est includé automatiquement si on l'utilise avec notre plugin Visual Composer.
	 * ATTENTION: Si utilisé hors de Visual Composer, il faut aller décommenter l'include JS et CSS dans Le fichier de Enqueue!
	 *
	 * @private
	 */
	_setAOS() {
		if (typeof AOS === 'function' || typeof AOS === 'object') {
			AOS.init({
				// Global settings:
				// disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
				// startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
				// initClassName: 'aos-init', // class applied after initialization
				// animatedClassName: 'aos-animate', // class applied on animation
				// useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
				// disableMutationObserver: false, // disables automatic mutations' detections (advanced)
				// debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
				// throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

				// // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
				// offset: 120, // offset (in px) from the original trigger point
				// delay: 0, // values from 0 to 3000, with step 50ms
				// duration: 400, // values from 0 to 3000, with step 50ms
				// easing: 'ease', // default easing for AOS animations
				// once: false, // whether animation should happen only once - while scrolling down
				// mirror: false, // whether elements should animate out while scrolling past them
				// anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

			});
		}
	}

	/**
	 *
	 * _setSwiperCarousels
	 * * Swiper carousel est includé si on utilise un module VC. Si on le veut hors-VC il faut l'ajouter dans Henri_Theme_Enqueue (juste à décommenter les lignes pour le CSS et le JS)
	 * Pour ajouter des formats ou des thèmes, ça se fait dans Henri_Theme_VisualComposer
	 *
	 * @private
	 *
	 * Documentation:
	 * https://swiperjs.com/get-started/
	 * https://swiperjs.com/api/
	 *
	 *
	 */
	_setSwiperCarousels() {

		//Répéter le code suivant pour chaque thème, juste à remplacer la classe "theme-henri".
		$('.swiper-container-parent.theme-henri').each(function (index, element) {
			var nextEl = $(this).find(".swiper-button-next")
			var prevEl = $(this).find(".swiper-button-prev")
			var swiper = new Swiper($(this).find(".swiper-container"), {
				direction: 'horizontal',
				loop: false,
				breakpoints: {
					0: {
						// slidesPerView: 1.3,
						// spaceBetween: 8,
						// slidesPerGroup:3
					},
					500: {
						// slidesPerView: 2.2,
						// spaceBetween: 8,
						// slidesPerGroup:2
					},
					768: {
						// slidesPerView: 2.4,
						// spaceBetween: 16,
						// slidesPerGroup:2
					},
					992: {
						// slidesPerView: 2.3,
						// spaceBetween: 32,
						// slidesPerGroup:2
					},
					1024: {
						// slidesPerView: 2.4,
						// spaceBetween: 32,
						// slidesPerGroup:2
					}
					,
					1280: {
						// slidesPerView: 3.3,
						// spaceBetween: 32,
						// slidesPerGroup:3
					}
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
				},
				navigation: {
					nextEl: nextEl,
					prevEl: prevEl,
				},
			})
		})

		//Patch pour que ça se rafraichisse quand on ouvre un modal
		$(document).on("shown.bs.modal", function (event) {
			setTimeout(function () {
				$(event.target).find(".swiper-container").each(function () {
					this.swiper.update();
				});
			}, 500);
		});
	}


	/**
	 * Set Magnific Popup
	 * IMPORTANT: Pour utiliser, décommenter la fonction plus haut et décommenter les 2 includes dans le fichier Enqueue.
	 * @private
	 */
	_setMagnificPopup() {
		//Image simple
		this._$el.find('.image-pop-link').magnificPopup(
			{
				type: 'image'
			}
		);
		//Gallerie d'images
		this._$el.find('.image-gallery-pop-link').magnificPopup(
			{
				type: 'image',
				gallery: {
					enabled: true
				}
			}
		);
		//Vidéo - Youtube autoplay (à ajuster pour Vimeo ?)
		this._$el.find(".video-popup").magnificPopup(
			{
				disableOn: 0,
				type: 'iframe',
				mainClass: 'mfp-fade',
				removalDelay: 160,
				preloader: false,
				fixedContentPos: false,
				iframe: {
					patterns: {
						youtube_short: {
							index: 'youtu.be/',
							id: 'youtu.be/',
							src: 'https://www.youtube.com/embed/%id%?autoplay=1&rel=0&mute=0&enablejsapi=1'
						},
						youtube: {
							index: 'youtube.com/',
							id: function (url) {
								var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
								if (!m || !m[1]) return null;
								return m[1];
							},
							src: 'https://www.youtube.com/embed/%id%?autoplay=1&rel=0&mute=0&enablejsapi=1'
						},
					},
					markup: '<div class="mfp-iframe-scaler">' +
						'<div class="mfp-close"></div>' +
						'<iframe class="mfp-iframe" frameborder="0" allowfullscreen  allow="autoplay"></iframe>' +
						'</div>',
				}
			}
		);
	}

	/**--------------------------------------------------------------
     toString
     --------------------------------------------------------------*/

	toString() {
		return "Henri.App";
	}

}


export { App };
