/*--------------------------------------------------------------
 ArticlesListing > Class
 By Stef Funaro
 www.stefetmarie.com
 @created 11 November 2021
 --------------------------------------------------------------*/

var EventEmitter = require('events');

class ArticlesListing extends EventEmitter {

	/**--------------------------------------------------------------
	 CONSTRUCTOR
	 --------------------------------------------------------------*/

	constructor() {
		super();

	}


	/**--------------------------------------------------------------
	 PUBLIC FUNCTIONS
	 --------------------------------------------------------------*/

	init() {
		console.log("ArticlesListing.init!");

		var instance = this;
		this.app = window.Henri.app;

		this.$articlesContainer = $("#articles-content");
		this.$loading = $("#articles-loading");
		this.$bandeFiltres = $("header .filtres-container");
		this.$pagination = $("#articles-pagination");

		this.filteredUrl = "/articles/tous/tous/tous"

		this.$bandeFiltres.find(".js-select").each(function () {
			var $select = $(this);
			$select.find(".dropdown-item").click(function (pE) {
				pE.preventDefault();
				instance._onFilterSelect($select, $(this));
			});
		});

		this._$grid = $(".vignettes-container");
		this._$grid.masonry({
			// options
			itemSelector: '.grid-item',
			//columnWidth: 390,
			// gutter: 80
			transitionDuration: 0,
			gutter: '.gutter-sizer',
		});

		this.$pagination.find(".pagination .page-link-previous").data("page",0);
		this.$pagination.find(".pagination .page-link-previous").click(function (pE) {
			pE.preventDefault();
			instance.$articlesContainer.data("page", instance.$articlesContainer.data("page")-1);
			instance._loadData(true);
		});
		this.$pagination.find(".pagination .page-link-next").click(function (pE) {
			pE.preventDefault();
			instance.$articlesContainer.data("page", instance.$articlesContainer.data("page")+1);
			instance._loadData(true);
		});


		this._loadData();
	}


	/**--------------------------------------------------------------
	 GETTER
	 --------------------------------------------------------------*/


	/**--------------------------------------------------------------
	 PRIVATE FUNCTIONS(wanna be)
	 --------------------------------------------------------------*/

	_onFilterSelect($select, $item) {
		$select.find(".active").removeClass("active");
		$item.addClass("active");
		$select.closest(".filtre-dropdown").find(".select-txt").html($item.text());
		this.$articlesContainer.data("page", 0);
		$("#navbar-main-collapse-content .btn-close").click();
		this._loadData(true);
	}

	_loadData(subrequest = false) {
		var instance = this;

		this.$articlesContainer.css("opacity", 0)
		var page = this.$articlesContainer.data("page");
		this.$pagination.hide();
		this.$loading.show();

		// if(page < 1){
		this.$articlesContainer.html("");
		//this.$offres.find(".js-counter").html("");
		// }
		// this.$loadmore.hide();
		// this.$offres.find(".back-btn").removeClass("active");

		var page = this.$articlesContainer.data("page");

		//categories
		var category = "tous";
		this.$bandeFiltres.find(".js-select.select-categories .dropdown-item.active").each(function () {
			category = $(this).data("id");
			$(this).closest(".filtre-dropdown").find(".select-txt").html($(this).text());
		});
		// console.log("categories=", categories);

		//Regions
		var region = "tous";
		this.$bandeFiltres.find(".js-select.select-region .dropdown-item.active").each(function () {
			region = ($(this).data("id"));
			$(this).closest(".filtre-dropdown").find(".select-txt").html($(this).text());
		});

		//document-types
		var doctype = "tous";
		this.$bandeFiltres.find(".js-select.select-document-types .dropdown-item.active").each(function () {
			doctype = ($(this).data("id"));
			$(this).closest(".filtre-dropdown").find(".select-txt").html($(this).text());
		});

		if (subrequest) {
			this.app.nav.scrollToElemId("henri-project-body");
		}
		this.filteredUrl = "/articles/"+category+"/"+region+"/"+doctype+"/"+(page+1)
		//if(page > 0){
			// this.filteredUrl += "/"+(page+1);
		//}
		history.pushState({}, "", this.filteredUrl);


		$(".js-mobile-filter-display").html("");
		if (category !== "tous") {
			$(".js-mobile-filter-display").append(category + " ")
		}
		if (region !== "tous") {
			$(".js-mobile-filter-display").append(region + " ")
		}
		if (doctype !== "tous") {
			$(".js-mobile-filter-display").append(doctype)
		}
var data = {
	"action": "get_articles_list",
	"posts_per_page": this.$articlesContainer.data("page-size"),
	"page": page,
	"region": region,
	"category": category,
	"document-type": doctype,
}
		console.log("_loadData",data);
		$.ajax({
			type: "POST",
			url: ajaxurl,
			dataType: "json",
			data: data,
			success: function (response) {
				//alert(response);
				//setTimeout(function () {
				console.log("success", response);
				//var json = $.parseJSON(response);
				instance._showData(response);
				//},1000);
			}
		})

	}

	_showData(response) {
		//console.log("response.html=", response.html);
		if (response.items.length > 0) {
			var items = response.items;
			var html = "";
			for (var i = 0; i < items.length; i++) {
				html += items[i];
			}
			//this._onWindowResize();
			this.$articlesContainer.append(html);
		} else {
			this.$articlesContainer.append("<div class='no-results'>Aucun article trouvé</div>");
		}

		// if(response.total > 1){
		// 	this.$offres.find(".js-counter").html(response.total+" offres trouvées");
		// }else{
		// 	this.$offres.find(".js-counter").html(response.total+" offre trouvée");
		// }
		// if(this._hasFilters){
		// 	this.$offres.find(".back-btn").addClass("active");
		// 	this._hasFilters = false;
		// }

		console.log("pages", response.pages);

		this.$articlesContainer.data("pages-count", response.pages);

		//this.$pagination

		this.$loading.hide();
		// if(this.$offres.find(".item").length < response.total){
		// 	this.$loadmore.show();
		// }
		this._loadedInit = true;
		this._onListUpdate();
	}


	_onListUpdate() {
		var instance = this;
		console.log("_onListUpdate!");

		this._$grid.find(".tag-box a").hover(function (pE) {
			$(this).parent().find(".more-content").show();
		}, function (pE) {
			$(this).parent().find(".more-content").hide();
		});
		this._$grid.masonry('reloadItems');
		instance._$grid.masonry('layout');
		this._$grid.imagesLoaded().progress(function () {
			console.log("imageLoaded!");
			instance._$grid.masonry('layout');
		});

		// this.$articlesContainer.css("opacity",1);
		this.$articlesContainer.animate({opacity: 1}, 300);

		this._articlesListUpdatePagesNav();

		this.app._setShare();

	}

	_articlesListUpdatePagesNav() {
		var instance = this;
		var pagesCount = this.$articlesContainer.data("pages-count");
		this.$pagination.find(".pagination .page-item-num").remove();
		this.$pagination.find(".trois-dot").remove();
		if (pagesCount < 2) return;
		console.log("pagesCount=", pagesCount);
		var curpage = this.$articlesContainer.data("page");
		var itemsHtlm = "";
		var active;

		var pages = this._calcPagesList(pagesCount, (curpage+1), 6);
		var page;
		for (var i = 0; i < pages.length; i++) {
			page = pages[i];
			if(page === 0){
				itemsHtlm += '<li class="page-item trois-dot"><a class="page-link" href="#">...</a></li>';
			}else{
				active = ((page-1) === curpage) ? " current-page" : "";
				itemsHtlm += '<li class="page-item page-item-num ' + active + '" data-page="' + (page-1) + '"><a class="page-link" href="#">' + (page) + '</a></li>';
			}
		}
		// console.log("itemsHtlm=", itemsHtlm);
		this.$pagination.find(".pagination .page-link-previous").after(itemsHtlm);

		this.$pagination.find(".pagination .page-item-num").click(function (pE) {
			pE.preventDefault();
			instance.$articlesContainer.data("page", $(this).data("page"));
			instance._loadData(true);
		});

		if(curpage > 1){
			this.$pagination.find(".pagination .page-link-previous").show();
		}else{
			this.$pagination.find(".pagination .page-link-previous").hide();
		}

		if(curpage < (pagesCount-1)){
			this.$pagination.find(".pagination .page-link-next").show();
		}else{
			this.$pagination.find(".pagination .page-link-next").hide();
		}


		this.$pagination.fadeIn();
	}

	_calcPagesList(totalPages, page, maxLength) {
		if (maxLength < 5) throw "maxLength must be at least 5";

		function range(start, end) {
			return Array.from(Array(end - start + 1), (_, i) => i + start);
		}

		var sideWidth = maxLength < 9 ? 1 : 2;
		var leftWidth = (maxLength - sideWidth*2 - 3) >> 1;
		var rightWidth = (maxLength - sideWidth*2 - 2) >> 1;
		if (totalPages <= maxLength) {
			// no breaks in list
			return range(1, totalPages);
		}
		if (page <= maxLength - sideWidth - 1 - rightWidth) {
			// no break on left of page
			return range(1, maxLength - sideWidth - 1)
				.concat(0, range(totalPages - sideWidth + 1, totalPages));
		}
		if (page >= totalPages - sideWidth - 1 - rightWidth) {
			// no break on right of page
			return range(1, sideWidth)
				.concat(0, range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
		}
		// Breaks on both sides
		return range(1, sideWidth)
			.concat(0, range(page - leftWidth, page + rightWidth),
				0, range(totalPages - sideWidth + 1, totalPages));
	}

	_onWindowResize() {

	}

	/**--------------------------------------------------------------
	 toString
	 --------------------------------------------------------------*/

	toString() {
		return "Henri.ArticlesListing";
	}

};
export {ArticlesListing};

