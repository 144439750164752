/**
 * Configs
 */

window.HenriConfigs = {
	//"tweenMainEase" : Quint,
	"imageFolder": "images/",
	"videoFolder": "video/"
};

// $.ajaxSetup ({
// 	cache: false
// });

