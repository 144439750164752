/**--------------------------------------------------------------
 App - Main App Class
 By Stef Funaro
 www.stefetmarie.com
 --------------------------------------------------------------*/

import {ThrottleHelper} from "../_helpers/ThrottleHelper";

var EventEmitter = require('events');

class Layout extends EventEmitter {

	/**--------------------------------------------------------------
	 CONSTRUCTOR
	 --------------------------------------------------------------*/

	constructor() {
		super();

		this.app = window.Henri.app;
		this._mainEl = this.app.container;
		this._$mainEl = this.app.$container;

		//EVENTS
		this.ON_DOC_RESIZE = "henri-app-ready";
		this.ON_BREAKPOINT_CHANGE = "henri-breakpoint-change";

		//Get breakpoints
		this._initBreakpoints();

		//Leave at off if you don't use it for better performance
		this.checkBreakpointChangeOnResize = false;

		this.lastBreakpoint = null;
		this.currentBreakpoint = null;


	}

	/**--------------------------------------------------------------
	 PUBLIC
	 --------------------------------------------------------------*/

	/**
	 * do start!
	 */
	start() {

		var instance = this;

		//Match Height global. Utilisez data-mh="my-group" pour faire des groupes.
		this._$mainEl.find('.matchHeight').matchHeight();

		//on resize event (direct or throttle, use ONLY ONE);
		// $(window).on('resize', this._onWindowResize.bind(this)).trigger('resize');
		$(window).on('resize', ThrottleHelper.throttle(this._onWindowResize,1000,this)).trigger('resize');

		//on orientation change event
		$(window).on("orientationchange", this._onWindowResize.bind(this));




		this._initLayout();

		this._hackVisualComposer();

		console.log("Layout.start()");
	}

	/**
	 * do stop!
	 */
	stop() {
		//TODO: stop!!
		console.log("//TODO: stop!!");
	}


	/**--------------------------------------------------------------
	 GETTER
	 --------------------------------------------------------------*/

	/**
	 * @returns {float}
	 */
	get winW() {
		return this._winW;
	}

	/**
	 * @returns {float}
	 */
	get winH() {
		return this._winH;
	}

	/**
	 * @returns {float}
	 */
	get viewportW() {
		return this._viewportW;
	}

	/**
	 * @returns {float}
	 */
	get viewportH() {
		return this._viewportH;
	}

	/**
	 * Is the viewport smaller than breakpoint ?
	 * @param pBreakpoint {string}
	 * @returns {boolean}
	 */
	isUnderBreakpoint(pBreakpoint) {
		return (this._viewportW < this["bp_" + pBreakpoint]);
	}

	/**
	 * is Breakpoint bigger or equal than breakpoint ?
	 * @param pBreakpoint {string}
	 * @returns {boolean}
	 */
	isOverBreakpoint(pBreakpoint) {
		return (this._viewportW >= this["bp_" + pBreakpoint]);
	}

	/**--------------------------------------------------------------
	 PRIVATE
	 --------------------------------------------------------------*/

	/**
	 * @private
	 */
	_initLayout() {

		//Automatic centering Vertical - Only once on load
		$(".js-center-v-once").each(this._centerV);

		//Automatic centering Horizontal - Only once on load
		$(".js-center-h-once").each(this._centerH);
	}

	/**
	 * Hack de Visual Composer
	 * @private
	 */
	_hackVisualComposer(){
		// $(".vc_row[data-vc-full-width='true']").wrapInner( "<div class='vc_row_inner'></div>");
		$(".vc_row").wrapInner( "<div class='vc_row_inner'></div>");
	}


	/**
	 * Centrage vertical dans son parent
	 * @private
	 */
	_centerV() {
		if (this.style.position === "static") {
			this.style.position = "relative";
		}
		var t = Math.floor($(this).parent().height() / 2) - $(this).height() / 2;
		if (t < 0) {
			t = 0;
		}
		this.style.top = t +"px";
	}

	/**
	 * Centrage horizontal dans son parent
	 * @private
	 */
	_centerH() {
		if (this.style.position === "static") {
			this.style.position = "relative";
		}
		var l = Math.floor($(this).parent().width() / 2) - $(this).width() / 2;
		if (l < 0) {
			l = 0;
		}
		this.style.left = l +"px";
	}

	/**
	 * on Resize Event!
	 * @param pE jQueryEvent
	 * @private
	 */
	_onWindowResize(pE) {
		this._upSise();
		$(".js-center-v").each(this._centerV);
		$(".js-center-h").each(this._centerH);

        //Pour faire un 100vh sur mobile
        //console.log("breakpoint nav break=", this.app.nav.navCollapseBreakpointSize);
        if(this._viewportW < this.app.nav.navCollapseBreakpointSize){
            $("#navbar-main-collapse-content").css("min-height",this.winH-$(".nav-header").height());
        }else{
            $("#navbar-main-collapse-content").css("min-height",0);
        }

		this.emit(this.ON_DOC_RESIZE,this._winW,this._winH);
	}

	/**
	 * Update sizes
	 * @private
	 */
	_upSise() {
		this._winW = $(window).width();
		// if(_winW < _minW){
		// 	_winW = _minW;
		// }else{
		// }
		this._winH = $(window).height();

		this._viewportW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		this._viewportH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

		//Check breakpoint change
		if (this.checkBreakpointChangeOnResize) {
			this.lastBreakpoint = this.currentBreakpoint;
			for (var i = 0; i < this.breakpointsNamesArray.length; i++) {
				if (this._viewportW < this["bp_" + this.breakpointsNamesArray[i]]) {
					this.currentBreakpoint = this.breakpointsNamesArray[i];
				} else {
					break;
				}
			}
			if (this.currentBreakpoint !== this.lastBreakpoint) {
				this._onBreakpointChange();
			}
		}
	}

	/**
	 * Breakpoint change event
	 * @private
	 */
	_onBreakpointChange() {
		this.emit(this.ON_BREAKPOINT_CHANGE,this.currentBreakpoint);
	}

    /**
     * Stockage de breakpoints du site
     * @private
     */
    _initBreakpoints(){

        //Breakpoints list
        var breakpointsFromCss = this.app.getCSSRootVariable("breakpoints-names");
        this.breakpointsNamesArray = breakpointsFromCss.split(',');
        console.log("this.breakpointsNamesArray=", this.breakpointsNamesArray);

        //NOW WE DETECT FROM CSS VARIABLES

        //Breakpoints for responsive - same has the ones used in the CSS
        // this.breakpointsNamesArray = ["infiniti", "5xl","4xl","xxxl","xxl","xl", "lg", "md", "sm", "xs"];
        // this.bp_infinite = 9999999999999;
        // this.bp_xl = 1200;
        // this.bp_lg = 992;
        // this.bp_md = 768;
        // this.bp_sm = 576;
        // this.bp_xs = 0;

        var bp;
        for(var i=0;i<this.breakpointsNamesArray.length;i++){
            bp = parseInt(this.app.getCSSRootVariable("breakpoint-"+this.breakpointsNamesArray[i]),10);
            this["bp_"+this.breakpointsNamesArray[i]] = (isNaN(bp)) ? 0 : bp;
            console.log("bp=",this.breakpointsNamesArray[i], this["bp_"+this.breakpointsNamesArray[i]]);
        }
    }

	/**--------------------------------------------------------------
	 toString
	 --------------------------------------------------------------*/

	toString() {
		return "Henri.App.layout";
	}

}


export {Layout};