/*--------------------------------------------------------------
 SocialHelper > Static Class
 By Stef Funaro
 www.stefetmarie.com
 @created 23 August 2017

To use: window.Henri.app.socialHelper.facebookShare();

 --------------------------------------------------------------*/


class SocialHelper {



	/**--------------------------------------------------------------
	 PUBLIC STATIC FUNCTIONS
	 --------------------------------------------------------------*/

    /**
     * Facebook Sharing
     * @param pUrl
     * @param pTitle
     * @param pDescr
     * @param pImage
     */
    static facebookShare(pUrl) {
        var width  = 575,
            height = 400,
            left   = ($(window).width()  - width)  / 2,
            top    = ($(window).height() - height) / 2,
            opts   = 'toolbar=0,status=0' +
                ',width='  + width  +
                ',height=' + height +
                ',top='    + top    +
                ',left='   + left;
        var url = 'https://www.facebook.com/sharer/sharer.php?u=' + pUrl;

        window.open(url, 'sharer', opts);
    }

	/**
	 * Twitter Sharing
	 * @param pText
	 */
	static twitterShare(pText){
		var width  = 575,
			height = 400,
			left   = ($(window).width()  - width)  / 2,
			top    = ($(window).height() - height) / 2,
			url    = "http://twitter.com/share?text="+pText,
			opts   = 'toolbar=0,status=0' +
				',width='  + width  +
				',height=' + height +
				',top='    + top    +
				',left='   + left;

		window.open(url, 'twitter', opts);
	}

	/**
	 * Linkedin Sharing
	 * @param pUrl
	 * @param pTitle
	 * @param pSummary
	 */
	static linkedinShare(pUrl,pTitle,pSummary){
		if (typeof pSummary === "undefined" || pSummary === null) {
			pSummary = "";
		}
		if (typeof pTitle === "undefined" || pTitle === null) {
			pTitle = "";
		}

		var width  = 575,
			height = 400,
			left   = ($(window).width()  - width)  / 2,
			top    = ($(window).height() - height) / 2,
			url    = "https://www.linkedin.com/shareArticle?mini=true&url="+pUrl+"&title="+pTitle+"&summary="+pSummary+"&source=LinkedIn",
			opts   = 'toolbar=0,status=0' +
				',width='  + width  +
				',height=' + height +
				',top='    + top    +
				',left='   + left;

		window.open(url, 'twitter', opts);
	}

	static emailShare(pUrl,pSubject){
		window.location.href = "mailto:?subject="+encodeURIComponent(pSubject)+"&body="+pUrl;
	}


	/**--------------------------------------------------------------
	 toString
	 --------------------------------------------------------------*/

	toString() {
		return "Henri.SocialHelper";
	}

}

export {SocialHelper};
