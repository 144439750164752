

(function () {
   'use strict';
}());

import {App} from "./_classes/App";



/**--------------------------------------------------------------
 * Main JS script file
 * By Stef Funaro
 * www.stefetmarie.com
 * @author Stef Funaro <stef@stefetmarie.com>
 *
 *     IMPORTANT NOTE: This JS is compiled with gulp and use gulp-concat, the list of files to concatenate is in package.json
 ---------------------------------------------------------------
 */


window.$ = jQuery.noConflict();

window.Henri = {};
window.Henri.app = new App();

// window.onbeforeunload = function () {
//     window.scrollTo(0, 0);
// }

//ON DOC READY
jQuery(document).ready(function($){



   $( "#copy-to-clipboard" ).on( "click", function(e) {

      e.preventDefault();

      var link = $(this).data("link");
      var temp = $("<input>");
      $("body").append(temp);
      temp.val(link).select();
      document.execCommand("copy");
      temp.remove();
   } );





	// window.onload = function() {
	setTimeout (function () {
		window.Henri.app.init();
	}, 0);
	// }
});
