/*--------------------------------------------------------------
 Forms > Class
 By Stef Funaro
 www.stefetmarie.com
 @created 23 August 2017
 --------------------------------------------------------------*/

var EventEmitter = require('events');

class Forms extends EventEmitter {

	/**--------------------------------------------------------------
	 CONSTRUCTOR
	 --------------------------------------------------------------*/

	constructor() {
		super();

		this.app = window.Henri.app;
		this._mainEl = this.app.container;

	}


	/**--------------------------------------------------------------
	 PUBLIC FUNCTIONS
	 --------------------------------------------------------------*/

	init() {
		console.log("Forms.init()");
		// this.contactForm = this._mainEl.querySelector('#contact-form');
		// this.$contactForm = $(this.contactForm);
		// this.contactForm.addEventListener('submit', this._onContactFormSubmit.bind(this));

		//Petit truc pour faire des dropdowns cutes avec Contact 7
		$("form.wpcf7-form").find('.dropdown-menu').on('click', '.dropdown-item', e => {
			let labelBy = $(e.delegateTarget).attr('aria-labelledby');
			let $button = $(`#${labelBy}`);
			let $linkedSelect = $(`select[name="${$button.data('linked-select')}"]`);
			$button.text($(e.currentTarget).text());
			$linkedSelect.val($(e.currentTarget).data('value')).trigger("change");
		});

        //Redirect en succès.
        //Mettre ceci dans le form: <input type="hidden" name="redirect" value="URL">
        // $(".wpcf7").on( 'wpcf7mailsent', function( event ) {
        //     var url = $(this).find("input[name='redirect']").val();
        //     if(url && url != ""){
        //         window.location.href = url;
        //     }
        // } );

        //Select 2
        $("select.select2").each(function () {
            let dropclasses = "";
            if($(this).hasClass("no-search")){
                dropclasses += "no-search ";
            }
            $(this).select2(
                {
                    theme:"bootstrap4",
                    dropdownCssClass:dropclasses,
                    dropdownAutoWidth : true,
                    width: 'auto'
                }
            );
        });

	}


	/**--------------------------------------------------------------
	 GETTER
	 --------------------------------------------------------------*/


	/**--------------------------------------------------------------
	 PRIVATE FUNCTIONS(wanna be)
	 --------------------------------------------------------------*/

	//testPri
	_onContactFormSubmit(pE) {
		pE.preventDefault();
		console.log("Forms._onContactFormSubmit!",pE);
	}

	_validateEmail(sEmail) {
		var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
		if (filter.test(sEmail)) {
			return true;
		}
		else {
			return false;
		}
	}

	/**--------------------------------------------------------------
	 toString
	 --------------------------------------------------------------*/

	toString() {
		return "Henri.Forms";
	}

}
export {Forms};

