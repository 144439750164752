/*--------------------------------------------------------------
 Navigation > Class
 By Stef Funaro
 www.stefetmarie.com
 @created 22 August 2017
 --------------------------------------------------------------*/

import {ThrottleHelper} from "../_helpers/ThrottleHelper";

var EventEmitter = require('events');

class Navigation extends EventEmitter {

	/**--------------------------------------------------------------
     CONSTRUCTOR
     --------------------------------------------------------------*/

	constructor(pMainEl) {
		super();

		this.app = window.Henri.app;
		this._mainEl = this.app.container;
		this._$mainEl = this.app.$container;

		//Events
		this.ON_READY = "henri-on-navigation-ready-event";

		//Properties
		this._scrollToSpeed = 1;

		this._scrollToOffset = 0;

		this.lastScroll = 0;

		this.stickyNav = false;
		this.$stickyNavEl = null;
		this.headerHeight = 0;

		this.navCollapseBreakpointSize = parseInt(this.app.getCSSRootVariable("navbar-collapse-size"), 10);

		//Sticky
		this.stickyNavStyleSwitchedPosition = 300;
		this.stickyNavShowHidePosition = 300;
		this.navSticked = false;


		//Go!
		//Call init from outside.
	}


	/**--------------------------------------------------------------
     PUBLIC FUNCTIONS
     --------------------------------------------------------------*/

	/**
	 * init!
	 */
	init() {

		var instance = this;

		this._$headContainer = this._$mainEl.find("header");
		this._$mainNavContainer = this._$headContainer.find("#nav-principale");
		this._$mainNav = this._$mainNavContainer.find("#navbar-main");
		this._scrollToOffset = this._$mainNav.outerHeight();

		//Scroll links
		var elements = this._mainEl.getElementsByClassName('scroll-link');
		for (var i = 0; i < elements.length; i++) {
			elements[i].addEventListener("click", function (pEvent) {
				pEvent.preventDefault();
				var sectionID = this.getAttribute("href");
				instance.scrollToElemId(sectionID);
			});
		}

		//Anchors links
		this._$mainEl.find('a[href^="#"]').not('[href="#"]').on("click touchstart", function (pE) {
			if (this.hasAttribute('data-vc-accordion')) return;
			if (this.hasAttribute('data-vc-tabs')) return;
			if (this.hasAttribute('data-toggle')) return;
			pE.preventDefault();
			var sectionID = $(this).attr("href");
			var $elem = $(sectionID);
			if ($elem.length < 1) {
				sectionID = sectionID.replace('#', '');
				$elem = $("a[name='" + sectionID + "']");
			}
			if ($elem.length > 0) {
				console.log("lien # scroll to=", $elem);
				return instance.scrollToElem($elem);
			}
		});

		$(".accordion").each(function () {
			var $accordion = $(this);
			$accordion.find(".accordion-button").click(function (pE) {
				pE.preventDefault();
				console.log("accordion scroll to=", $(this));
				// instance.scrollToElem($(this));
			});
		});

		//Scroll to accordion //TODO: Ne pas cibler menu, juste les accordeons
		$(document).on('shown.bs.collapse', function (event) {
			//console.log( "in! print e: " +event.type);
			// event.target.scrollIntoView();
			console.log("accordion collapse scroll to=", $(this));
			instance.scrollToElem($(event.target));
		});


		//Sticky nav
		this.$stickyNavEl = this._$mainEl.find(".sticky-nav");
		this.stickyNav = this.$stickyNavEl.length > 0;


		//Keypress
		// document.addEventListener('keypress', this._onKeyPress.bind(this));

		//MouseWheel
		//Todo:Mouse wheel

		//On Scroll
		$(document).on('scroll', ThrottleHelper.throttle(this._onScroll, 100, this)).trigger('scroll');

		// //Nav burger - open/closed state
		// this._$mainNav.on('show.bs.collapse', function () {
		//     document.body.classList.add("opened-nav");
		//     instance._mainEl.classList.add("opened-nav");
		// });
		// this._$mainNav.on('hide.bs.collapse', function () {
		//     document.body.classList.remove("opened-nav");
		//     instance._mainEl.classList.remove("opened-nav");
		// });

		this._setBurgerNav();

		if (this.stickyNav) {
			this._fixAnchorLinksForFixedHeader();
		}

		if (this.hasHashValue()) {
			var hash = this.getHashValue();
			this.scrollToElemId(hash);
		}

		this._$headContainer.find(".recherche-logo-box .btn-loupe").click(function (pE) {
			pE.preventDefault();
			instance._$headContainer.find(".recherche-open-box").fadeIn();
		});
		this._$headContainer.find(".recherche-open-box .ico-box").click(function (pE) {
			pE.preventDefault();
			instance._$headContainer.find(".recherche-open-box").fadeOut();
		});

		$("footer .dropdown").each(function () {
			var $dropdown = $(this);
			$dropdown.find(".dropdown-toggle").click(function (pE) {
				pE.preventDefault();
				if($dropdown.hasClass("opened")){
					$dropdown.find(".dropdown-menu").slideUp();
				}else{
					$dropdown.find(".dropdown-menu").slideDown();
				}
				$dropdown.toggleClass("opened");
			});
		});

		//Ready!!
		this.emit(this.ON_READY);

		console.log("Navigation.init()");
	}


	/**
     Goto - by position
     @param pPos
	 */
	gotoSectionPos(pPos) {
		//_gotoSectionId(_sectionsList[pI].id);
	}

	/**
	 * gotoSectionId
	 * @param pId {string}
	 */
	gotoSectionId(pId) {
		console.log("Navigation._gotoSectionId!", pId);
	}


	/**
	 * scroll to ID
	 * @param pElemId {string}
	 */
	scrollToElemId(pElemId) {
		console.log("_scrollToElemId pElemId=", pElemId);
		this.scrollToElem($("#" + pElemId));
	}

	/**
	 * scroll to element
	 * @param $pElem {jqueryElement}
	 */
	scrollToElem($pElem) {
		var targetOffset = $pElem.offset().top - this._scrollToOffset;
		// $('html,body').animate({scrollTop:targetOffset}, this._scrollToSpeed);
		this.scrollToPos(targetOffset);
	}

	/**
	 * scroll to position
	 * @param pPos {int}
	 * @private
	 */
	scrollToPos(pPos) {
		// TweenLite.to(window, this._scrollToSpeed, {scrollTo: pPos});
		$('html,body').animate({scrollTop:pPos}, this._scrollToSpeed);
	}

	/**
	 * Get hash value in URL
	 * return {string}
	 */
	getHashValue() {
		var value = false;
		var hash = location.hash;
		if (hash)
			value = location.hash.substr(1);

		return value;
	}

	/**
	 * Get has Hash Value in URL
	 * @return {bool}
	 */
	hasHashValue() {
		var hash = location.hash;
		return !!hash;
	}

	/**
	 * onDocResize
	 */
	onDocResize(pW, pH) {
		//console.log("onDocResize pW=", pW,"pH",pH);
		if (this.stickyNav) {
			this.headerHeight = this._$headContainer.outerHeight();
			//Position à laquelle la sticky nav se cache (qu'on applique la classe .nav-scrolled-hide)
			// this.stickyNavShowHidePosition = this.headerHeight;
			this.stickyNavStyleSwitchedPosition = this._$headContainer.find(".banner-box").height();
		}
	}

	/**--------------------------------------------------------------
     GETTER
     --------------------------------------------------------------*/


	/**--------------------------------------------------------------
     PRIVATE FUNCTIONS(wanna be)
     --------------------------------------------------------------*/

	/**
	 * Burger Nav
	 * @private
	 */
	_setBurgerNav() {
		var instance = this;
		//Burger click
		$("button[data-toggle='collapse-henri']").click(function (pE) {
			pE.preventDefault();
			if ($(this).hasClass("collapsed")) {
				$("button[data-toggle='collapse-henri']").removeClass("collapsed");
				instance._openBurgerNav();
			} else {
				$("button[data-toggle='collapse-henri']").addClass("collapsed");
				instance._closeBurgerNav();
			}
		});
	}

	_openBurgerNav() {
		document.body.classList.add("opened-nav");
		this._mainEl.classList.add("opened-nav");
		// this._containerGen.classList.add("opened-nav");
		this._$mainNav.fadeIn({duration: 150, easing: "linear"});
		// this._$mainNav.slideDown({duration:150,easing:"linear)};
	}

	_closeBurgerNav() {
		document.body.classList.remove("opened-nav");
		this._mainEl.classList.remove("opened-nav");
		// this._containerGen.classList.remove("opened-nav");
		this._$mainNav.fadeOut({duration: 150, easing: "linear"});
		// this._$mainNav.slideUp({duration:150,easing:"linear)};
	}


	/**
	 * On Wheel EVENT
	 * @param pEvent
	 * @returns {boolean}
	 * @private
	 */
	_onMouseWheel(pEvent) {
		//console.log("Navigation._onMouseWheel pEvent=", pEvent);
		return false;
	}

	/**
	 * On key press event
	 * List of key codes: http://www.cambiaresearch.com/articles/15/javascript-char-codes-key-codes
	 * @param pEvent
	 * @private
	 */
	_onKeyPress(pEvent) {
		// console.log("Navigation._onKeyPress key=", key);
		var key = pEvent.keyCode || pEvent.which;
		// if(event.keyCode == 38) {
		// 	pEvent.preventDefault()
		// 	log('Navigation_onKeyPress","up arrow was pressed');
		// }
	}

	_onScroll(pEvent) {
		let scrollTop = $(document).scrollTop();
		// console.log("_onScroll scrollTop=", scrollTop);
		var instance = this;
		if (this.stickyNav) {
			//Petit truc pour faire disparaitre nav en scroll et réaparaitre en scroll up
			//console.log("_onScroll this.stickyNavBuffSize=", this.stickyNavBuffSize);
			if (scrollTop < this.stickyNavShowHidePosition) {
				document.body.classList.remove("nav-scrolled-hide");
				this._mainEl.classList.remove("nav-scrolled-hide");
			} else {
				if (this.lastScroll <= scrollTop) {
					document.body.classList.add("nav-scrolled-hide");
					this._mainEl.classList.add("nav-scrolled-hide");
				} else {
					document.body.classList.remove("nav-scrolled-hide");
					this._mainEl.classList.remove("nav-scrolled-hide");
				}
			}
			this.lastScroll = scrollTop;
			//Petit truc qui change le look de la nav en scroll
			var $sticky = $(".sticky-nav");
			if (scrollTop > this.stickyNavStyleSwitchedPosition) {
				if (!this.navSticked) {
					this.navSticked = true;
					$sticky.css("position", "fixed").css("top", (0 - scrollTop) + "px");
					$sticky.animate({"top": -1 - ($sticky.find(".banner-box").height())}, 400, function () {
						console.log("Finished!");
						document.body.classList.add("nav-scrolled");
						instance._mainEl.classList.add("nav-scrolled");
					});
				}

				// $(".sticky-nav").css("position", "fixed").css("top", (0 - this.stickyNavStyleSwitchedPosition) + "px");
				// setTimeout(function () {
				// 		document.body.classList.add("nav-scrolled");
				// 		instance._mainEl.classList.add("nav-scrolled");
				// 	}, 10);
			} else {
				if (this.navSticked) {
					this.navSticked = false;
					$sticky.stop();
					document.body.classList.remove("nav-scrolled");
					instance._mainEl.classList.remove("nav-scrolled");
					$sticky.css("position", "absolute").css("top", (scrollTop - $sticky.find(".banner-box").height()) + "px");
					$sticky.animate({"top": 0}, 400);
				}
				// $(".sticky-nav").css("position", "absolute").css("top",0);
			}
		}
	}

	/**
     Fix header for anchor links
	 */
	_fixAnchorLinksForFixedHeader() {
		var instance = this;
		// $('a[href*=#]:not([href=#])').click(function () {
		//     if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
		//
		//         var target = $(this.hash);
		//         target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
		//         if (target.length) {
		//             $('html,body').animate({
		//                 scrollTop: target.offset().top - instance._scrollToOffset //offsets for fixed header
		//             }, 1000);
		//             return false;
		//         }
		//     }
		// });
		//Executed on page load with URL containing an anchor tag.
		if (location.href.split("#")[1] === "") {
			return;
		}
		if ($(location.href.split("#")[1])) {
			var target = $('#' + location.href.split("#")[1]);
			if (target.length) {
				console.log("scroll to=", target);
				$('html,body').animate({
					scrollTop: target.offset().top - instance._scrollToOffset //offset height of header here too.
				}, 1000);
				return false;
			}
		}
	}


	/**--------------------------------------------------------------
     toString
     --------------------------------------------------------------*/

	toString() {
		return "Henri.Navigation";
	}

}


export {Navigation};

