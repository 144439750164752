/*--------------------------------------------------------------
 ThrottleHelper > Static Class
 By Stef Funaro
 www.stefetmarie.com
 @created 23 August 2017
 --------------------------------------------------------------*/

class ThrottleHelper {

	/**--------------------------------------------------------------
	 PUBLIC STATIC FUNCTIONS
	 --------------------------------------------------------------*/

	/**
	 * Throttle a function
	 * @param fn function
	 * @param threshhold integer
	 * @param scope
	 * @returns {Function}
	 */
	static throttle(fn, threshhold = 250, scope = false) {
		var last,
			deferTimer;
		return function () {
			var context = scope || this;
			var now = +new Date(),
				args = arguments;
			if (last && now < last + threshhold) {
				// hold on to it
				clearTimeout(deferTimer);
				deferTimer = setTimeout(function () {
					last = now;
					fn.apply(context, args);
				}, threshhold);
			} else {
				last = now;
				fn.apply(context, args);
			}
		};
	}

}

export {ThrottleHelper};