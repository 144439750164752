jQuery(document).ready(function ($) {
	// console.log("lang obj=", $("html").attr("lang"));
	if ($("html").attr("lang") == "fr_FR") {
		$.extend(true, $.magnificPopup.defaults, {
			tClose: 'Fermer (Esc)', // Alt text on close button
			tLoading: 'Chargement...', // Text that is displayed during loading. Can contain %curr% and %total% keys
			gallery: {
				tPrev: 'Précédent', // Alt text on left arrow
				tNext: 'Suivant', // Alt text on right arrow
				tCounter: '%curr% sur %total%' // Markup for "1 of 7" counter
			},
			image: {
				tError: '<a href="%url%">Image</a> introuvable.' // Error message when image could not be loaded
			},
			ajax: {
				tError: '<a href="%url%">Contenu</a> introuvable.' // Error message when ajax request failed
			}
		});
	}
});