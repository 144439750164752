/*--------------------------------------------------------------
 PositionHelper > Static Class
 By Stef Funaro
 www.stefetmarie.com
 @created 23 August 2017
 --------------------------------------------------------------*/


class PositionHelper {

	/**--------------------------------------------------------------
	 PUBLIC STATIC FUNCTIONS
	 --------------------------------------------------------------*/

	static convertToAbsolutePos(pItemsArr, pContainer) {

		var arr = [];
		// var e;
		var o;
		var cnt = 0;
		var i;
		for (i = 0; i < pItemsArr.length; i++) {
			// console.log("pItemsArr[i]!="+pItemsArr[i]);
			pContainer.find(pItemsArr[i]).each(function () {
				// if($(this).css("position") != "absolute"){
				$(this).css({display: "inline-block"});
				o = $(this).position();
				// console.log("i="+i)
				// console.log("n="+pItemsArr[i])
				// console.log("top="+o.top)
				// console.log("left="+o.left)
				// console.log("margin-top="+parseFloat(e.css("margin-top"),10))
				// console.log("padding-top="+parseFloat(e.css("padding-top"),10))
				arr[cnt] = {
					e: $(this),
					t: o.top,
					l: o.left
				};
				cnt++;
				// }
			});

		}
		for (i = 0; i < arr.length; i++) {
			arr[i].e.css({position: "absolute", top: arr[i].t, left: arr[i].l});
			arr[i].e.data("oT", arr[i].t);
		}
		// console.log("pos="+pos);
		// pos = 0;

	}

	/**
	 * Backup position
	 * @param pItem
	 */
	static backupPos($pItem) {
		$pItem.data("oT", parseFloat($pItem.css("top"), 10));
		$pItem.data("oL", parseFloat($pItem.css("left"), 10));
	}

	/**
	 * backup size of element
	 * @param $pItem
	 */
	static backupSize($pItem) {
		$pItem.data("oH", $pItem.height());
		$pItem.data("oW", $pItem.width());
	}

	/**
	 * Backup properties (dimension and position) of element
	 * @param $pItem
	 */
	static backupProps($pItem) {
		$pItem.data("oH", $pItem.height());
		$pItem.data("oW", $pItem.height());
		$pItem.data("oT", parseFloat($pItem.css("top"), 10));
		$pItem.data("oL", parseFloat($pItem.css("left"), 10));
	}

}

export {PositionHelper};
