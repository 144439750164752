

/**
 * Shuffle Array
 * @param o
 * @returns {*}
 */
Array.prototype.henri_shuffle = function()
{
	var i = this.length;
	while (i)
	{
		var j = Math.floor(Math.random() * i);
		var t = this[--i];
		this[i] = this[j];
		this[j] = t;
	}
	return this;
};
// function henri_shuffle_array(o){ //v1.0
//     for(var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
//     return o;
// };